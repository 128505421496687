import { Switch, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { DecInput } from 'components';
import styles from './OutlayParamDrawer.module.scss';

const SwitchProps = {
  checkedChildren: <CheckOutlined />,
  unCheckedChildren: <CloseOutlined />,
  size: 'large',
};

const OutlayForm = ({ data = {}, isDisabled }) => (
  <div className={styles.FormContainer}>
    <div>
      <span>
        <label htmlFor='VAT' title='НДС'>
          НДС:
        </label>
        <Switch
          name='VAT'
          disabled={data.type === 'act' || isDisabled}
          checked={data.VAT}
          onChange={() => data.editOutlay('VAT', !data.VAT)}
          {...SwitchProps}
        />
      </span>
      <span>
        <label htmlFor='installment' title='Рассрочка'>
          Рассрочка:
        </label>
        <Switch
          name='installment'
          disabled={data.type === 'act' || isDisabled}
          checked={data.installment}
          onChange={() => data.editOutlay('installment', !data.installment)}
          {...SwitchProps}
        />
      </span>
      {!isDisabled && data.type === 'outlay' && (
        <span>
          <label htmlFor='isExtra' title='Дополнительная'>
            {'Доп.\u{2009}смета:'}
          </label>
          <Switch
            disabled={data.isFinal}
            name='isExtra'
            checked={data.isExtra}
            onChange={() => data.editOutlay('isExtra', !data.isExtra)}
            {...SwitchProps}
          />
        </span>
      )}
    </div>
    <div>
      <span>
        <label htmlFor='coefficient' title='Коэффициент сметы'>
          Коэффициент сметы:
        </label>
        <DecInput
          name='coefficient'
          value={data.coefficient}
          disabled={data.type === 'act' || isDisabled}
          onNativeChange={(value) => data.editOutlay('coefficient', value)}
          size='middle'
          min='1'
          max='10'
        />
      </span>
    </div>
    <div>
      <span>
        <label htmlFor='note' title='Заметка'>
          Заметка:
        </label>
        <Input.TextArea
          name='note'
          key={data.note}
          defaultValue={data.note}
          onBlur={(e) => data.editOutlay('note', e.target.value)}
          maxLength={99}
          autoSize={
            data.type === 'outlay'
              ? {
                minRows: 4,
                maxRows: 4,
              }
              : {
                minRows: 2,
                maxRows: 2,
              }
          }
        />
      </span>
    </div>
  </div>
);

export default OutlayForm;
